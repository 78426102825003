<template>
    <v-container fluid>
		<v-container>
			<v-row dense>
				<v-col>
					<template v-for="(item, index) in parents">
						<router-link class="bread" :to="item.href" :key="item.href">{{ item.text}}</router-link>
						<span v-if="index < parents.length - 1" :key="item.href">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
					</template>
<!--
					<v-breadcrumbs :items="parents">
						<template v-slot:divider>
							<v-icon>mdi-chevron-right</v-icon>
						</template>
					</v-breadcrumbs>
-->
				</v-col>
			</v-row>
			<v-row dense>
				<v-col>
					<v-card>
						<v-card-title>{{ $t('product.title_sub') }}</v-card-title>
						<v-card-text>
							<v-treeview v-model="tree" :open="initially_open" :items="items" activatable item-key="id" open-on-click>
								<template slot="label" slot-scope="props">
									<router-link :to="create_route(props.item.id)" v-if="!props.item.children">{{ props.item.label }}</router-link>
									<span v-else>{{ props.item.label }}</span>
								</template>
							</v-treeview>
						</v-card-text>
					</v-card>
                </v-col>
            </v-row>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	name: 'ProductMenu',
	data: () => ({
		tree: [],
        parents: [],
		initially_open: [],
		items: [],
	}),
	methods: {
		create_route(id) {
			return "/productCategory/" + id;
		},
		bread(parents) {
			let breadc = "";
			for(let i = 0; i < parents.length; i++) {
				if(i < parents.length - 2) breadc = breadc + '<router-link :to="' + parents[i].href + '">' + parents[i].text + '</router-link>&nbsp;>&nbsp;'
				else breadc = breadc + '<router-link :to="' + parents[i].href + '">' + parents[i].text + '</router-link>'
			}
			return breadc;
		}
	},
	mounted() {
		ApiService.get(`/subcat/` + this.$route.params.id).then((response) => {
			this.items = JSON.parse(response.data.tree)
            this.parents = response.data.parents
		}).catch(() => {});
		this.$store.dispatch('set_cat_data', localStorage.CAT_DATA)	
	},
};
</script>

<style scoped>
.v-treeview-node__root {
	color: red;
	min-height: 13px;
}
a:link, a:visited, a:hover, a:active {
	color: black;
	text-decoration: none;
}
.container {
	padding-top: 0px;
}
.bread {
	color: #1976d2;
	text-decoration: none;
	cursor: pointer;
	font-size: 14px;
}
</style>
